import { useStaticQuery, graphql, Link } from "gatsby"
import React from "react"

const Hicdex = () => {
  const data = useStaticQuery(graphql`
  query mySecondaryMarketSales {
    site {
      siteMetadata {
        address
        author
        description
        title
      }
    }
    hicdex {
      hic_et_nunc_swap(where: {
            token: {creator: {address: {_neq: "tz1gthv2uBERqmpmXwoCkSbffWTrkfwWwrH4"}}},
            status: {_in: [0]},
            creator: {address: {_eq: "tz1gthv2uBERqmpmXwoCkSbffWTrkfwWwrH4"}}
          }, order_by: {id: desc}) {
        price
        status
        token {
          title
          mime
          description
          id
          artifact_uri
          display_uri
          thumbnail_uri
          creator {
            address
            name
          }
          supply
        }
      }
    }
  }  
  `)
  return (
  <>
    <div>

      <p>Hi, I'm <a href="https://twitter.com/generatethings">@generatethings</a></p>

      <p>Here are the OBJKTs that I'm selling on hicetnunc</p>

      <p>My HEN profile is <a href="https://teia.art/tz/tz1gthv2uBERqmpmXwoCkSbffWTrkfwWwrH4">https://teia.art/tz/tz1gthv2uBERqmpmXwoCkSbffWTrkfwWwrH4</a></p>

      {/* <div class="wallet">
        What I'm selling (address {data.site.siteMetadata.address})
      </div> */}

<style>{`
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');
* {
  font-family: 'DM Mono';
  font-size: 16px;
}

.tokens {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 40px 0;
}
@media only screen and (max-width: 960px) {
  .tokens {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .tokens {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 468px) {
  .tokens {
    grid-template-columns: 1fr;
  }
}
.token {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.token-preview {
  background-color: #eee;
  margin-bottom: 20px;
}
.token-preview img {
  height: 256px;
  max-width: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
}
.token-metadata {
  margin: 15px;
}
.token-metadata div {
  margin-bottom: 10px;
}
`}</style>

      <div class="tokens">
        {data.hicdex.hic_et_nunc_swap.map((swap, i) => (
          <div class="token token-{swap.token.id}">

            <div class="token-preview">
              {/* <a href={`https://www.hicetnunc.art/objkt/` + swap.token.id}><img src={swap.token.display_uri} height="200" /></a> */}
              {/* <a href={`https://www.hicetnunc.art/objkt/` + swap.token.id}><img src={`https://10.via0.com/ipfs/` + swap.token.display_uri.replace('ipfs://', '')} height="200" /></a> */}
              {/* <a href={`https://teia.art/objkt/` + swap.token.id}><img src={`https://gateway.ipfs.io/ipfs/` + swap.token.display_uri.replace('ipfs://', '')} height="200" /></a> */}
              <a href={`https://teia.art/objkt/` + swap.token.id}><img src={`https://ipfs.io/ipfs/` + swap.token.display_uri.replace('ipfs://', '')} height="200" /></a>
            </div>

            <div class="token-metadata">

              <div class="token-price">
                <b>Price ..... Supply</b><br />{swap.price / 1000000} tez ..... 1/{swap.token.supply}
              </div>

              <div class="token-id">
                <b>OBJKT #</b><br /><a href={`https://teia.art/objkt/` + swap.token.id}>{swap.token.id}</a>
              </div>

              <div class="token-price">
                <b>Name</b><br /><a href={`https://teia.art/objkt/` + swap.token.id}>{swap.token.title}</a>
              </div>

              <div class="token-creator">
                <b>Creator</b><br /><a href={`https://teia.art/tz/` + swap.token.creator.address}>{swap.token.creator.name}</a>
              </div>

              <div class="token-description">
                <b>Description</b><br />{swap.token.description}
              </div>

              <div class="token-links">
                <b>Buy it at</b><br />
                <a href={`https://teia.art/objkt/` + swap.token.id}>TEIA</a> / 
                <a href={`https://objkt.com/asset/hicetnunc/` + swap.token.id}>OBJKT</a>
              </div>

              {/* <hr />

<div class="token-price">
  <b>artifact_uri</b><br />{swap.token.artifact_uri}
</div>

<div class="token-price">
  <b>display_uri</b><br />{swap.token.display_uri}
</div> */}

            </div>

            {/* <dl> */}

              {/* <dt>OBJKT</dt>
              <dd>{swap.token.id}</dd>
              
              <dt>price</dt>
              <dd>{swap.price / 1000000}</dd>

              <dt>name</dt>
              <dd>{swap.token.title}</dd> */}

              {/* <dt>mime</dt>
              <dd>{swap.token.mime}</dd> */}

              {/* <dt>description</dt>
              <dd>{swap.token.description}</dd> */}

              {/* <dt>preview</dt>
              <dd>
              <img src={swap.token.display_uri} height="200" />
              </dd> */}
              
              {/* <dt>display_uri</dt>
              <dd>
              {(swap.token.mime === `video/mp4`) && 
                <video controls>
                  <source type={swap.token.mime} source={swap.token.display_uri} />
                </video>
              }
              {(swap.token.mime.startsWith('image/')) && 
                <img src={swap.token.display_uri} />
              }
              </dd> */}

            {/* </dl> */}


          </div>
        ))}
      </div>
      
    </div>
  </>
  )
}

export default Hicdex