import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hicdex from "../components/hicdex"

const IndexPage = () => (
  <Layout>
    <Seo title="Hicetnunc secondary sales for @generatethings" />
    <Hicdex />
  </Layout>
)

export default IndexPage
